import { Chain, ChainData } from '@jdiyield/interfaces'

export const chains = {
  eth: 1,
  bsc: 56,
  polygon: 137,
  avax: 43114,
  ftm: 250,
  ronin: 2020
}

export type ChainID = 1 | 56 | 97 | 137 | 999 | 2020 | 43114 | 250

export const chainData: Record<ChainID, ChainData> = {
  1: {
    chainID: 1,
    chainName: Chain.ETHEREUM,
    dexguruID: 'eth',
    blockExplorerUrls: ['https://etherscan.io/'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18
    }
  },
  56: {
    chainID: 56,
    chainName: Chain.BINANCE_SMART_CHAIN,
    dexguruID: 'bsc',
    blockExplorerUrls: ['https://bscscan.com/'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18
    }
  },
  97: {
    chainID: 97,
    chainName: Chain.BSC_TESTNET,
    dexguruID: '',
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18
    }
  },
  137: {
    chainID: 137,
    chainName: Chain.POLYGON,
    dexguruID: 'polygon',
    blockExplorerUrls: ['https://explorer.matic.network/'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18
    }
  },
  999: {
    chainID: 999,
    chainName: Chain.TERRA,
    dexguruID: 'terra',
    blockExplorerUrls: ['https://terra.stake.id/#/'],
    nativeCurrency: {
      name: 'LUNA',
      symbol: 'luna',
      decimals: 18
    }
  },
  2020: {
    chainID: 2020,
    chainName: Chain.RONIN,
    dexguruID: 'ronin',
    blockExplorerUrls: ['https://explorer.roninchain.com/'],
    nativeCurrency: {
      name: 'AXS',
      symbol: 'AXS',
      decimals: 18
    }
  },
  43114: {
    chainID: 43114,
    chainName: Chain.AVAX,
    dexguruID: 'avalanche',
    blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    }
  },
  250: {
    chainID: 250,
    chainName: Chain.FTM,
    dexguruID: 'fantom',
    blockExplorerUrls: ['https://rpc.ftm.tools/'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18
    }
  }
}
export const GAS_LIMIT = 500000

export const nodes = {
  1: process.env.NEXT_PUBLIC_ETH_FULLNODE_URL
    ? [process.env.NEXT_PUBLIC_ETH_FULLNODE_URL]
    : ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  56: process.env.NEXT_PUBLIC_BSC_FULLNODE_URL
    ? [process.env.NEXT_PUBLIC_BSC_FULLNODE_URL]
    : ['https://bsc-dataseed1.ninicoin.io', 'https://bsc-dataseed1.defibit.io', 'https://bsc-dataseed1.defibit.io'],
  97: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  137: process.env.NEXT_PUBLIC_POLYGON_FULLNODE_URL
    ? [process.env.NEXT_PUBLIC_POLYGON_FULLNODE_URL]
    : ['https://polygon-rpc.com'],
  2020: ['https://api.roninchain.com/rpc'],
  43114: ['https://api.avax.network/ext/bc/C/rpc'],
  250: ['https://rpc.ftm.tools']
}
